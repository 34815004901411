@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  background-color: black;
  height: 100%;
  width: 100%;
  color: white;
  font-family: "Italiana", serif !important;
  font-weight: bold;

}

.btn {
  margin-top: 10px;
}

.header {
  text-align: center;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

img[alt~="roulette-static"] { 
  position: absolute; 
  z-index: 5; 
  width: 10%; 
  right: 35px; 
  top: 35px; 
  transform: rotate(45deg);
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAABHCAYAAACu56/AAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANKSURBVHgB1ZqBddsgEIZPXqDeIHSCeINqg6YbaIR0gigTNJ3A7gRNJ5AzQdIJUCZINvgLFdSuLVl3SGD8vXcvec9wcIKD46CgmQCwNH9KJ1dGVkaWTjytk3cjT0a2RVG80DmxHTdya6RBONrI2oiilLjO3xl5w7yskxhjGqkidD6NMei+foN0aCMVzYVRtnJKz8EdTcUZEHv6jPGNQsnEAM+apJhKCvkY4OmdWsWAAXaDejaiKAy7mW2NvLr/PdfUbYKKwqnMBvljtJQxYoMwGiMlQ/9qQht2dqixBm4gp7EdIyHopmyIMc2YYg0ZNU0EYRtoeUpZUgP22pauhM2QIi1QUtPMGJ0lZJRTFDQUCaP7QdCPn4eVJQ6mKBLoYjTN7IedfsvFXv1PzHY2Zp1uKRJGt91XvjOL2/2sWxXRLXVcSoqMGw2ukz/4keCu8a35UluKjBuNJ2bxa6kRvykdW2a5lTdCMSukPNS3zHL/HHvJrPBO6WC35Y34wCyf0gg23ohXZnlFGeKN4H7hK0oHOyr2RrTM8uJwewLctl68EVtmBRtpcheBqXAjiNe/Rrh8KHdK3VJkXFSgmMUf9ys+Mrf5t9ijAVmSTu1XlByIwvNA4wZI+tEcVpYEXZaSZgZdIKoFfaj6lDQCBeNZB5kBknOEb3/Zp0h6PNQIyHL0tGtH4AUy1qcUhmT9gpO+6D6chhx1SmmNMDQEqXjX+QZh/DcKRY9yO8808SPbQ1rqNs8n2t3REe2OklY+07Q47OPoERmyjENqjnxhKKGsqBuNHDkahUVfKVfoF+XHfd80Wpyo8EB50RrZ9P0waITLaozfA6TjfsiZi1O1MvKNR2PAl6EfT00n7xv3dH6+0hQgDwznpqY5gPzeYi7mncpI+5rAwwouF8QntW9sojwjQrpwRCPWHQjSOXlFMUH3SCsm0a7SDg1pEA9FKYD8GMulppQg/FnDEOnDG8zv5Dd0DhB+Hj9E/pZpZkM0pqGR+jlpjxFTnbyiHED4kvtMuYDwp3aKcgJyJ68pNyBLCOeaDhI9vasoZzDu5OfdEzigewY3OI2QmzMPgeHDU0WXAvrjqnydeQgcH54UXSJ7Tl7TpYLdVVaqVwhxSDGN/gC9k0SvNFPpdAAAAABJRU5ErkJggg=="); 
}
