.react-list-editable {
  ul {
    margin-bottom: 8px;
  }

  .input-field {
    padding: 10px;
    margin: 0 10px;
    background-color: #fafafa;
    border: 1px solid gray;
    border-radius: 3px;
    outline: none;
  }

  .new-input-field {
    margin-left: 16px;
    outline: none;
  }

  .input-field-container {
    margin-bottom: 8px;
    .delete-btn {
      justify-content: center;
      padding: 0;
      border: none;
      font: inherit;
      align-items: center;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: inline-flex;
      border: 1px solid gray;
      border-radius: 12px;
    }

    .delete-img {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
